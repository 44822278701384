import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import benefits from '../../../assets/images/benefits-img.jpg'
import features from '../../../assets/images/features-img.jpg'

class commercialGatePage extends React.Component {
  render() {
    const siteTitle =
      'Commercial Gate Services - Arlington, TX and Dallas-Fort Worth, TX'
    const siteDescription =
      'We install customized commercial gates that are perfect for your business. Contact us now for information on our custom gate services.'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />

        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">
              Commercial Gate Services in Dallas-Fort Worth, TX
            </h1>
            <hr />
            <p className="text-white mx-auto">
              Do you need a gate installed for your commercial property?
              Commercial gates help provide the security you need by limiting
              vehicle and foot traffic to designated areas and protect against
              damage to storefronts, doors, and windows. You can use them at
              warehouses, commercial office buildings, retail centers, and
              manufacturing facilities.{' '}
              <strong className="primary-color">
                Call J & J Gates Service and Design at{' '}
                <a href="tel:+1-817-466-2794">(817) 466-2794</a> for commercial
                gate services in Dallas-Fort Worth, TX.
              </strong>{' '}
              In addition to helping you select the best commercial gate for
              your Dallas-Fort Worth business, our expert staff will also work
              with you to resolve any operational or maintenance issues that may
              arise. BENEFITS
            </p>
          </div>
        </div>

        <div className="container d-padding-t d-padding-b">
          {' '}
          <div className="row text-justify">
            <div className="col-md-8">
              <div>
                <h4 className="mt-0">Benefits</h4>
                <hr className="ml-0" />
                <p>
                  Security may be the primary reason most companies buy gates,
                  but don’t ignore the fact that they can also improve the
                  appearance of your property as well as add value. Available in
                  many designs, shapes, and sizes, commercial security gates
                  play an integral role in achieving the look you are striving
                  for.
                </p>
                <p>
                  Think of them as an additional marketing tool, much like the
                  sign on your building. Let us help your Dallas-Fort Worth
                  business find the right style and select the exact components
                  needed for a successful application, including posts, hinges,
                  and miscellaneous hardware. We will ensure they are
                  professionally{' '}
                  <Link to="/services/gate-installation">installed</Link> and in
                  working order.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <LazyLoadImage
                  src={benefits}
                  title="Benefits"
                  alt="Stop Gate"
                />
              </div>
            </div>
          </div>
          <div className="row d-padding-t text-justify">
            <div className="col-md-4">
              <div>
                <LazyLoadImage
                  className="m-0"
                  src={features}
                  alt="Barrier Gate"
                />
              </div>
            </div>
            <div className="col-md-8">
              <div>
                <h4 className="mt-0">Features</h4>
                <hr className="ml-0" />
                <p>
                  There are different types of entrance gates ranging from
                  simple to elaborate. We can install barrier gates, traffic
                  control gates, pedestrian gates and more. They can often be
                  made fully functional using a simpler, lower-cost design.
                  While we commonly make commercial gates out of steel, you
                  might want to consider iron gates for their strength and
                  beauty. In some cases, ornamental wrought iron gates can
                  provide a classic or elegant look that is difficult to achieve
                  with steel and other materials.
                </p>
              </div>
            </div>
          </div>
          <div className="d-padding-t set-width text-center mx-auto">
            <div>
              <div>
                <h4 className="mt-0">Automation</h4>
                <hr />
                <p>
                  <Link to="/services/gate-automation">Automatic gates</Link>{' '}
                  make life easier for everyone. Various combinations of keypads
                  and remote controls allow authorized users to quickly and
                  easily navigate through restricted areas. Additional resources
                  include cameras, closed-circuit TV, and timers. Whether your
                  gate is supplied and installed by us or someone else, it will
                  require maintenance at some point in time, due to use and
                  exposure to the elements. Call us for all of your commercial
                  gate repair and maintenance in Dallas-Fort Worth, TX! Our
                  trained staff is ready to provide the service you desire.
                </p>
              </div>
            </div>
            <div className="d-padding-t">
              <div>
                <h4 className="mt-0">Free Estimates</h4>
                <hr />
                <p>
                  Our commercial experts will help you select and install a gate
                  that will promote the safety and operational efficiency of
                  your Arlington or Dallas-Fort Worth business. Contact us today
                  for a free estimate at (817) 466-2794.{' '}
                  <strong>
                    We look forward to providing you with top quality commercial
                    gates in Dallas and Fort Worth, TX.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default commercialGatePage

export const commercialGatePageQuery = graphql`
  query commercialGatePageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
